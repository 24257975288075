import React, { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';
import { PERMISSION } from '@common-packages/customer-permissions-utils';
import { CalcStatuses } from '@tls/state-helpers';
import Spinner from '@tls/ui-spinner';
import { Tooltip } from '@pwc/appkit-react';

import { dateFormatter } from '../shared/formatters';
import config from '../config';
import AuthorizedCard from '../shared/displayComponents/card/authorizedCard.container';
import { showConfirmModal } from '../shared/confirmModal/store/actions';
import {
  periodSelector,
  taxYearSelector,
  jurisdictionIdSelector,
  entityIdSelector,
  consolidationIdSelector,
  filingTypeIdSelector,
  globalContextSelector,
  isFetchingGlobalContextSelector,
  isEntityLockedSelector,
  isFetchingEntityLockIndicatorSelector,
  isConsolLockedSelector,
  isFetchingLockedJurisdictionsSelector,
  jurisdictionDescriptionSelector,
  customerPermissionsSelector,
  isFetchingEntityDataCalculationSelector,
  isFetchingTaxReturnCalculationSelector,
  isFetchingConsolidatedMembersTaxReturnCalculationSelector,
  isFetchingEntityBatchCalculationSelector,
  isFetchingCalculationAllEntitiesForJurisdictionSelector,
  isFetchingInitiateEntityBatchCalculationSelector,
} from '../shared/store/selectors';
import {
  performEntityDataCalculation,
  performTaxReturnCalculation,
  performConsolidatedMembersTaxReturnCalculation,
  initiateEntityBatchCalculation,
  fetchLockIndicator,
  fetchJurisdictionsForWhichEntityIsLocked,
  initiateCalculationAllEntitiesForJurisdiction,
  forceEntityBatchCalculation,
} from '../shared/store/actions';
import { filingTypes, RefreshStatus } from '../shared/constants';
import Loading from '../shared/displayComponents/loading.component';
import useFetch from '../shared/hooks/useFetch.hook';
import { fetchCalcStatus } from '../shared/store/calcStatus/actions';
import { calcStatusDataSelector } from '../shared/store/calcStatus/selectors';
import CalcStatusCalcButtonIndicator from '../shared/containerComponents/globalContext/calcStatusCalcButtonIndicator.container';
import { useQueryDREMap, useQueryFederalInformation } from '../shared/queries/manualCalculations';
import { useMutationPopulateDREData } from '../shared/mutations/manualCalculations';
import SelectContextDataInfo from '../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import { refreshStatusSourceMap } from '../tools/federalDataRefresh/federalDataRefresh.columnDefinitions';

import CalcStatusText from './calcStatusText.container';
import getHasPermissionExecuteForceEntityBatchCalc from './hasPermissionExecuteForceEntityBatchCalc';
import * as api from './store/api';
import styles from './manualCalculations.module.scss';

export const autoFedRefreshMap = new Map([
  ['Y', 'Automatic'],
  ['N', 'Manual'],
  [null, 'Manual'],
]);

const getDisplayText = (refreshStatus, fieldValue) => {
  switch (refreshStatus) {
    case RefreshStatus.NotStarted:
      return 'Initial Refresh Needed';
    case RefreshStatus.SourceMissingData:
      if (!fieldValue) {
        return 'Source Data Does Not Exist';
      } else {
        return dateFormatter(fieldValue, config.AMERICAN_DATE_TIME_FORMAT);
      }
    default:
      return dateFormatter(fieldValue, config.AMERICAN_DATE_TIME_FORMAT);
  }
};

const ManualCalculations = () => {
  const dispatch = useDispatch();

  const customerPermissions = useSelector(customerPermissionsSelector);
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const entityId = useSelector(entityIdSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const consolidationId = useSelector(consolidationIdSelector);
  const filingType = useSelector(filingTypeIdSelector);
  const globalContext = useSelector(globalContextSelector);
  const isFetchingGlobalContext = useSelector(isFetchingGlobalContextSelector);

  const isFetchingLockedJurisdictions = useSelector(isFetchingLockedJurisdictionsSelector);
  const isEntityLocked = useSelector(isEntityLockedSelector);
  const isFetchingEntityLockIndicator = useSelector(isFetchingEntityLockIndicatorSelector);
  const isConsolLocked = useSelector(isConsolLockedSelector);
  const jurisdictionDescription = useSelector(jurisdictionDescriptionSelector);

  ///
  const isFetchingEntityDataCalculation = useSelector(isFetchingEntityDataCalculationSelector);

  const isFetchingTaxReturnCalculation = useSelector(isFetchingTaxReturnCalculationSelector);
  const isFetchingConsolidatedMembersTaxReturnCalculation = useSelector(
    isFetchingConsolidatedMembersTaxReturnCalculationSelector,
  );
  const isFetchingCalculationAllEntitiesForJurisdiction = useSelector(
    isFetchingCalculationAllEntitiesForJurisdictionSelector,
  );
  const isFetchingEntityBatchCalculation = useSelector(isFetchingEntityBatchCalculationSelector);
  const isFetchingInitiateEntityBatchCalculation = useSelector(
    isFetchingInitiateEntityBatchCalculationSelector,
  );

  ///
  const isContextForEntityCalculationReady = Boolean(taxYear && period && entityId);
  const isContextForJurisdictionCalculationReady = Boolean(
    isContextForEntityCalculationReady && jurisdictionId,
  );

  const isContextForConsolidationCalculationReady = Boolean(taxYear && period && consolidationId);
  const isContextForConsolidationJurisdictionCalculationReady = Boolean(
    isContextForConsolidationCalculationReady && consolidationId,
  );

  const isContextReadyForSepOrConsol =
    filingType === filingTypes.SEPARATE
      ? isContextForEntityCalculationReady && isContextForJurisdictionCalculationReady
      : isContextForConsolidationCalculationReady &&
        isContextForConsolidationJurisdictionCalculationReady;

  const hasPermissionExecuteForceEntityBatchCalc = getHasPermissionExecuteForceEntityBatchCalc(
    customerPermissions,
  );
  const {
    mutateAsync: populateDREData,
    isLoading: isFetchingDREData,
  } = useMutationPopulateDREData();

  const calcStatusData = useSelector(calcStatusDataSelector);

  const { data: dreDate, isFetching: isFetchingDREMap } = useQueryDREMap({
    enabled: Boolean(globalContext.isReady),
    params: {
      period: globalContext.params.period,
      taxYear: globalContext.params.taxYear,
      businessEntityId: globalContext.params.businessEntityId,
    },
  });

  const { data: federalInfo, isFetching: isFetchingFederalInfo } = useQueryFederalInformation({
    enabled: Boolean(entityId),
    params: {
      period,
      taxYear,
      entityId,
    },
  });

  useEffect(() => {
    if (isContextForEntityCalculationReady) {
      dispatch(
        fetchJurisdictionsForWhichEntityIsLocked({
          taxYear,
          period,
          orgId: entityId,
        }),
      );
    }
  }, [dispatch, taxYear, period, entityId, isContextForEntityCalculationReady]);

  useEffect(() => {
    if (isContextForJurisdictionCalculationReady) {
      dispatch(
        fetchLockIndicator({
          taxYear,
          period,
          businessEntityId: filingType === filingTypes.SEPARATE ? entityId : consolidationId,
          jurisdictionId,
          filingTypeId: filingType,
        }),
      );
    }
  }, [
    dispatch,
    taxYear,
    period,
    jurisdictionId,
    filingType,
    isContextForJurisdictionCalculationReady,
    entityId,
    consolidationId,
  ]);

  const onBatchCalculationClick = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Perform calculation',
        text: 'Perform batch calculation?',
        confirmCallback: () => {
          dispatch(
            initiateEntityBatchCalculation({
              taxYear,
              orgId: entityId,
              period,
            }),
          );
        },
      }),
    );
  }, [dispatch, taxYear, entityId, period]);

  const onEntityDataCalculationClick = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Calculate Entity Data',
        text: `Calculate data for ${entityId}?`,
        confirmCallback: () => {
          dispatch(
            performEntityDataCalculation({
              taxYear,
              period,
              jurisdictionId,
              orgId: entityId,
            }),
          );
        },
      }),
    );
  }, [dispatch, entityId, taxYear, period, jurisdictionId]);

  const onConsolidatedCalculationClick = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Perform calculation',
        text: `Perform tax return calculation for ${consolidationId}?`,
        confirmCallback: () => {
          dispatch(
            performTaxReturnCalculation({
              taxYear,
              period,
              consolidationId,
              jurisdictionId,
            }),
          );
          dispatch(fetchCalcStatus(globalContext));
        },
      }),
    );
  }, [dispatch, consolidationId, taxYear, period, jurisdictionId, globalContext]);

  const onForceConsolidatedMemberCalculationsClick = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Perform calculation',
        text: `Perform tax return calculation for ${consolidationId} and consolidated members?`,
        confirmCallback: () => {
          dispatch(
            performConsolidatedMembersTaxReturnCalculation({
              taxYear,
              period,
              consolidationId,
              jurisdictionId,
            }),
          );
          dispatch(fetchCalcStatus(globalContext));
        },
      }),
    );
  }, [dispatch, consolidationId, taxYear, period, jurisdictionId, globalContext]);

  const onCalculateAllEntitiesForJurisdiction = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Calculate All Entities For Jurisdiction',
        text: `Calculating time is dependent on number of entities in the jurisdiction. Perform calculation for all entities for ${jurisdictionDescription} ?`,
        confirmCallback: () => {
          dispatch(
            initiateCalculationAllEntitiesForJurisdiction({
              taxYear,
              period,
              orgId: entityId,
              jurisdictionId,
            }),
          );
        },
      }),
    );
  }, [dispatch, entityId, jurisdictionDescription, jurisdictionId, period, taxYear]);

  const onPopulateDREFromBranch = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Populate DLE from DRE Branch',
        text: `Populate DLE data?`,
        confirmCallback: async () =>
          await populateDREData({
            taxYear,
            period,
            businessEntityId: globalContext.params.businessEntityId,
            jurisdictionId,
          }),
      }),
    );
  }, [
    dispatch,
    populateDREData,
    globalContext.params.businessEntityId,
    jurisdictionId,
    period,
    taxYear,
  ]);

  const {
    isFetching: isForcingEntityBatchCalculation,
    fetch: forceEntityBatchCalculation,
  } = useFetch({
    action: api.forceEntityBatchCalculation,
    successNotificationMessage: 'Force Entity Batch Calc succeeded',
  });

  const onForceEntityBatchCalculation = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Force Entity Batch Calc',
        text: 'Force Entity Batch Calc?',
        confirmCallback: () => {
          forceEntityBatchCalculation({ taxYear, period, entityId });
        },
      }),
    );
  }, [dispatch, forceEntityBatchCalculation, taxYear, period, entityId]);

  const renderCalcStatusIndicatorAndText = useCallback(() => {
    if (!globalContext.isReady || !calcStatusData) {
      return <Loading small isLoading />;
    }

    const { calcStatus, calcStatusCreatedOn, calcStatusTimestamp } = calcStatusData;

    // note: this is different from isFetchingCalcStatus, we don't want to show the spinner every 5 seconds
    const isCalcStatusLoading = Boolean(calcStatus && calcStatusCreatedOn && !calcStatusTimestamp);

    return (
      <Loading small isLoading={!calcStatus || isCalcStatusLoading}>
        <div className={styles.calcStatus}>
          <CalcStatusCalcButtonIndicator
            className={styles.calcStatusIndicator}
            shouldLinkToManualCalculations={false}
            calcIconSize={14}
          />
          <CalcStatusText />
        </div>
      </Loading>
    );
  }, [calcStatusData, globalContext]);

  const renderLastDREUpdate = useMemo(
    () =>
      dreDate?.lastUpdated ? (
        <span className={styles.lastUpdatedDate}>
          Last updated:{' '}
          {dateFormatter(dreDate.lastUpdated, config.AMERICAN_DATE_TIME_FORMAT_12_HOUR)}
        </span>
      ) : null,
    [dreDate],
  );

  const isLoading =
    isFetchingGlobalContext ||
    isFetchingLockedJurisdictions ||
    isFetchingEntityLockIndicator ||
    isForcingEntityBatchCalculation ||
    isFetchingDREMap;

  const isConsolCalcDisabled = calcStatusData?.calcStatus === CalcStatuses.IN_PROGRESS;
  const consolCalcDisabledTooltip = isConsolCalcDisabled
    ? `A calculation for ${consolidationId} is already in progress. Only one calculation per return can be run at a time.`
    : '';

  return (
    <>
      {filingType === filingTypes.SEPARATE && (
        <div className={styles.federalInformationPanel}>
          <div className={styles.federalInformationPanelHeader}>
            <h4>Federal Information {isFetchingFederalInfo ? <Spinner small /> : <></>}</h4>
          </div>
          <div className="row">
            <div className="col">
              <div className={styles.federalInformationField}>
                <label className={styles.federalInformationLabel}>
                  <Tooltip
                    content="Timestamp of when SLT sync'd with federal application."
                    placement="top"
                  >
                    SLT Updated On:
                  </Tooltip>
                </label>
                <span>
                  {getDisplayText(federalInfo?.gtwRefreshStatus, federalInfo?.sltUpdatedOn)}
                </span>
              </div>
            </div>

            <div className="col">
              <div className={styles.federalInformationField}>
                <label className={styles.federalInformationLabel}>
                  <Tooltip
                    content="Latest refresh status of entity's federal files."
                    placement="top"
                  >
                    Refresh Status:
                  </Tooltip>
                </label>
                <span>{refreshStatusSourceMap.get(federalInfo?.gtwRefreshStatus) || null}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className={styles.federalInformationField}>
                <label className={styles.federalInformationLabel}>
                  <Tooltip
                    content="Timestamp of when federal files were updated in external application."
                    placement="top"
                  >
                    Federal Source Updated On:
                  </Tooltip>
                </label>
                <span>
                  {getDisplayText(federalInfo?.gtwRefreshStatus, federalInfo?.gtwUpdatedDate)}
                </span>
              </div>
            </div>

            <div className="col">
              <div className={styles.federalInformationField}>
                <label className={styles.federalInformationLabel}>
                  <Tooltip
                    content="Federal return refresh setting which is either Automatic or Manual."
                    placement="top"
                  >
                    Refresh Type:
                  </Tooltip>
                </label>
                <span>{autoFedRefreshMap.get(federalInfo?.autoFedRefresh) || null}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {isContextReadyForSepOrConsol ? (
        <Loading isLoading={isLoading}>
          <div className="row row-with-cards">
            {filingType === filingTypes.SEPARATE ? (
              <>
                {isContextForJurisdictionCalculationReady && (
                  <>
                    <AuthorizedCard
                      title="Calculate Entity Data"
                      icon="refresh"
                      description="Calculate the current entity in context"
                      route={Routes.manualCalculations.MAIN}
                      permissionRoute={PERMISSION.ENTITY_DATA_CALCULATION}
                      onClick={onEntityDataCalculationClick}
                      locked={isEntityLocked}
                      accessOnlyWithEditPermission
                      disabled={isFetchingEntityDataCalculation}
                    />
                    {hasPermissionExecuteForceEntityBatchCalc && (
                      <AuthorizedCard
                        title="Force Calc All Jurisdictions for Entity"
                        icon="refresh"
                        description="Dirty and recalculate all of the jurisdictions for the entity in context"
                        route={Routes.manualCalculations.MAIN}
                        onClick={onForceEntityBatchCalculation}
                        disabled={isForcingEntityBatchCalculation}
                      />
                    )}
                  </>
                )}
                {isContextForEntityCalculationReady && (
                  <AuthorizedCard
                    title="Calc All Jurisdictions for Entity"
                    icon="refresh"
                    description="Calculate all of the jurisdictions for the entity in context"
                    route={Routes.manualCalculations.MAIN}
                    onClick={onBatchCalculationClick}
                    disabled={isFetchingInitiateEntityBatchCalculation}
                  />
                )}
                {isContextForJurisdictionCalculationReady && (
                  <AuthorizedCard
                    title="Calculate All Entities for Jurisdiction"
                    icon="refresh"
                    description="Calculate All Entities for Jurisdiction"
                    route={Routes.manualCalculations.MAIN}
                    onClick={onCalculateAllEntitiesForJurisdiction}
                    disabled={isFetchingCalculationAllEntitiesForJurisdiction}
                  />
                )}
                {Boolean(dreDate?.hasDREMapping) && (
                  <AuthorizedCard
                    title="Populate DLE from DRE Branch"
                    icon="refresh"
                    description="Populate federal and apportionment data for disregarded legal entity from branch"
                    route={Routes.manualCalculations.MAIN}
                    onClick={onPopulateDREFromBranch}
                    bottomCardElement={renderLastDREUpdate}
                    useSmallCardFormatWithBottomCardElement
                    disabled={isFetchingDREData}
                  />
                )}
              </>
            ) : (
              <>
                {isContextForConsolidationCalculationReady && (
                  <AuthorizedCard
                    title="Consolidated Calculation"
                    icon="refresh"
                    description="Consolidated Calculation"
                    route={Routes.manualCalculations.MAIN}
                    onClick={onConsolidatedCalculationClick}
                    bottomCardElement={renderCalcStatusIndicatorAndText()}
                    disabled={isConsolCalcDisabled}
                    locked={isConsolLocked}
                    tooltipContent={consolCalcDisabledTooltip}
                    disabled={isFetchingTaxReturnCalculation}
                  />
                )}
                {isContextForConsolidationCalculationReady && (
                  <AuthorizedCard
                    title="Force Consolidated Member Calculations"
                    icon="refresh"
                    description="Set consolidated members to dirty and calculate the consolidation"
                    route={Routes.manualCalculations.MAIN}
                    onClick={onForceConsolidatedMemberCalculationsClick}
                    bottomCardElement={renderCalcStatusIndicatorAndText()}
                    locked={isConsolLocked}
                    disabled={isConsolCalcDisabled}
                    tooltipContent={consolCalcDisabledTooltip}
                    disabled={isFetchingConsolidatedMembersTaxReturnCalculation}
                  />
                )}
              </>
            )}
          </div>
        </Loading>
      ) : (
        <SelectContextDataInfo />
      )}
    </>
  );
};

export default ManualCalculations;
