import { createSelector } from 'reselect';
import uniqBy from 'lodash.uniqby';
import { globalContextFactory } from '@tls/state-helpers';

import { filingTypes, filingTypesOptions } from '../../shared/constants';
import { filingStatesSelector } from '../../filingStates/store/selectors';

import { optionsSelectorFactory } from './storeHelpers';
import {
  consolidationIdSelector,
  isFetchingConsolidationsSelector,
  consolidationsOptionsSelector,
} from './consolidations.selectors';

export * from './consolidations.selectors';
export * from './customerData.selectors';

export const taxYearOptionsSelector = createSelector(
  [({ shared }) => shared.root.taxYearsData.taxYears],
  taxYears =>
    taxYears.map(({ name, description }) => ({
      label: description,
      value: name.toString(), // parsing toString because select component does not handle 0 well
    })),
);

export const taxYearSelector = ({ shared }) => shared.root.taxYear;
export const isFetchingTaxYearsDataSelector = state => state.shared.root.isFetchingTaxYearsData;

export const defaultTaxYearSelector = ({ shared }) => shared.root.taxYearsData.defaultTaxYear;

export const periodOptionsSelector = createSelector(
  [({ shared }) => shared.root.periods],
  periods =>
    periods.map(({ name, description }) => ({
      label: description,
      value: name.toString(), // parsing toString because select component does not handle 0 well
    })),
);

export const periodSelector = ({ shared }) =>
  Number.isInteger(shared.root.period) ? shared.root.period.toString() : null; // parsing toString because select component does not handle 0 well

export const periodNameSelector = createSelector(
  [periodSelector, periodOptionsSelector],
  (period, periodOptions) =>
    (periodOptions.find(({ value }) => value === period) || { label: null }).label,
);
export const isFetchingPeriodsSelector = ({ shared }) => shared.root.isFetchingPeriods;

export const filingTypeIdSelector = ({ shared }) => shared.root.filingTypeId;

export const filingTypeDescriptionSelector = createSelector(
  filingTypeIdSelector,
  filingTypeId => filingTypesOptions.find(({ value }) => value === filingTypeId)?.label,
);

export const isFetchingEntitiesSelector = ({ shared }) => shared.root.isFetchingEntities;
export const entityIdSelector = ({ shared }) => shared.root.entityId;
export const entitiesSelector = ({ shared }) => shared.root.entities;
export const entitiesOptionsSelector = createSelector([entitiesSelector], entities =>
  entities.map(({ entityId, entityName, entityTypeIndicator }) => ({
    label: `${entityId} ${entityTypeIndicator ? `(${entityTypeIndicator}) ` : ''}- ${entityName}`,
    value: entityId,
  })),
);
export const entityNameSelector = createSelector(
  [entitiesSelector, entityIdSelector],
  (entities, entityId) =>
    (entities.find(entity => entity.entityId === entityId) || { entityName: null }).entityName,
);

export const trgReportsSelector = ({ shared }) => shared.root.trgReports.trgReports;

export const isFetchingJurisdictionsSelector = ({ shared }) =>
  shared.root.isFetchingJurisdictionsOptions;
export const jurisdictionIdSelector = ({ shared }) => shared.root.jurisdictionId;
export const separateJurisdictionsOptionsSelector = createSelector(
  [filingStatesSelector],
  filingStates =>
    uniqBy(
      filingStates.map(({ jurisdictionId, jurisdictionDescription }) => ({
        label: jurisdictionDescription,
        value: jurisdictionId,
      })),
      'value',
    ),
);

export const consolidationJurisdictionsOptionsSelector = optionsSelectorFactory({
  listSelector: ({ shared }) => shared.root.jurisdictionsOptions,
  labelKey: 'jurisdictionDescription',
  valueKey: 'jurisdictionId',
});

export const consolidationJurisdictionsOptionsUsedInConsolSelector = optionsSelectorFactory({
  listSelector: ({ shared }) =>
    shared.root.jurisdictionsOptions.filter(jurisdiction => jurisdiction.usedInConsol),
  labelKey: 'jurisdictionDescription',
  valueKey: 'jurisdictionId',
});

export const jurisdictionDescriptionSelector = createSelector(
  [
    filingTypeIdSelector,
    jurisdictionIdSelector,
    separateJurisdictionsOptionsSelector,
    consolidationJurisdictionsOptionsSelector,
  ],
  (filingTypeId, jurisdictionId, separateJurisdictionsOptions, consolidationJurisdictionsOptions) =>
    filingTypeId === filingTypes.SEPARATE
      ? (
          separateJurisdictionsOptions.find(({ value }) => value === jurisdictionId) || {
            label: '',
          }
        ).label
      : (
          consolidationJurisdictionsOptions.find(({ value }) => value === jurisdictionId) || {
            label: '',
          }
        ).label,
);

export const jurisdictionStateCodeSelector = createSelector(
  jurisdictionIdSelector,
  ({ shared }) => shared.root.jurisdictionsOptions,
  (jurisdictionId, jurisdictionsOptions) =>
    jurisdictionsOptions.find(option => option.jurisdictionId === jurisdictionId)?.stateCode,
);

export const filingStateByJurisdictionIdSelector = createSelector(
  [filingStatesSelector, jurisdictionIdSelector],
  (filingStates, filingStatesJurisdictionId) =>
    [...filingStates].find(state => state.jurisdictionId === filingStatesJurisdictionId) || null,
);
export const filingStatesJurisdictionDescriptionSelector = createSelector(
  [filingStateByJurisdictionIdSelector],
  state => state && state.jurisdictionDescription,
);
export const filingStatesProfileIdSelector = createSelector(
  [filingStateByJurisdictionIdSelector],
  state => state && state.profileId,
);
export const filingStateCodeSelector = createSelector(
  [filingStateByJurisdictionIdSelector],
  state => state && state.stateCode,
);

export const DisplayTypes = {
  BREADCRUMB: 'breadcrumb',
  BROWSE: 'browse',
  DETAILS: 'details',
};

export const globalContextSelector = createSelector(
  [
    taxYearSelector,
    periodSelector,
    filingTypeIdSelector,
    entityIdSelector,
    consolidationIdSelector,
    jurisdictionIdSelector,
  ],
  (taxYear, period, filingTypeId, entityId, consolidationId, jurisdictionId) =>
    globalContextFactory({
      taxYear,
      period,
      filingTypeId,
      entityId,
      consolidationId,
      jurisdictionId,
    }),
);

export const isFetchingGlobalContextSelector = createSelector(
  [
    globalContextSelector,
    isFetchingTaxYearsDataSelector,
    isFetchingPeriodsSelector,
    isFetchingConsolidationsSelector,
    isFetchingEntitiesSelector,
    isFetchingJurisdictionsSelector,
  ],
  (
    globalContext,
    isFetchingTaxYearsData,
    isFetchingPeriods,
    isFetchingConsolidations,
    isFetchingEntities,
    isFetchingJurisdictions,
  ) => {
    if (globalContext.isConsolidated) {
      return Boolean(
        isFetchingTaxYearsData ||
          isFetchingPeriods ||
          isFetchingConsolidations ||
          isFetchingJurisdictions,
      );
    }

    return Boolean(
      isFetchingTaxYearsData || isFetchingPeriods || isFetchingEntities || isFetchingJurisdictions,
    );
  },
);

export const isGlobalContextReadySelector = createSelector(
  [globalContextSelector],
  globalContext => globalContext.isReady,
);

export const appConfigSelector = ({ shared }) => shared.root.appConfig;

export const isFetchingDataModelLinksSelector = ({ shared }) =>
  shared.root.isFetchingDataModelLinks;
export const dataModelLinksSelector = ({ shared }) => shared.root.dataModelLinks;

export const scrollElementIdSelector = ({ shared }) => shared.root.scrollElementId;

export const isSigningOutSelector = ({ shared }) => shared.root.isSigningOut;

export const isEntityLockedSelector = ({ shared }) => shared.root.isEntityLocked;

export const isConsolLockedSelector = ({ shared }) => shared.root.isConsolLocked;

export const isFetchingEntityLockIndicatorSelector = ({ shared }) =>
  shared.root.isFetchingEntityLockIndicator;

export const lockedJurisdictionsSelector = ({ shared }) => shared.root.lockedJurisdictions;
export const isFetchingLockedJurisdictionsSelector = ({ shared }) =>
  shared.root.isFetchingLockedJurisdictions;

export const orgIdByFilingTypeSelector = createSelector(
  [entityIdSelector, consolidationIdSelector, filingTypeIdSelector],
  (entityId, consolidationId, filingTypeId) =>
    filingTypeId === filingTypes.SEPARATE ? entityId : consolidationId,
);

export const businessEntityNameSelector = createSelector(
  filingTypeIdSelector,
  entityIdSelector,
  consolidationIdSelector,
  entitiesOptionsSelector,
  consolidationsOptionsSelector,
  (filingTypeId, entityId, consolidationId, entityOptions, consolidationOptions) =>
    filingTypeId === filingTypes.SEPARATE
      ? entityOptions.find(({ value }) => value === entityId)?.label
      : consolidationOptions.find(({ value }) => value === consolidationId)?.label,
);

export const businessEntityIdSelector = createSelector(
  filingTypeIdSelector,
  entityIdSelector,
  consolidationIdSelector,
  (filingTypeId, entityId, consolidationId) =>
    filingTypeId === filingTypes.SEPARATE ? entityId : consolidationId,
);

export const isPartnershipSelector = ({ shared }) => shared.root.isPartnership;
export const isFetchingIsPartnershipSelector = ({ shared }) => shared.root.isFetchingIsPartnership;

export const formsByPeriodSelector = ({ shared }) => shared.root.forms;
export const isFetchingFormsByPeriodSelector = ({ shared }) => shared.root.isFetchingForms;

export const formsByPeriodOptionsWithSwpFormIdSelector = optionsSelectorFactory({
  listSelector: formsByPeriodSelector,
  labelKey: 'formName',
  valueKey: 'swpFormId',
});

export const formsByPeriodOptionsWithFormIdSelector = optionsSelectorFactory({
  listSelector: formsByPeriodSelector,
  labelKey: 'formName',
  valueKey: 'formId',
});

export const primaryFilingFormOptionsSelector = optionsSelectorFactory({
  listSelector: formsByPeriodSelector,
  labelKey: 'formName',
  valueKey: 'shortFormName',
});

// Manual calculations selectors
export const isFetchingEntityDataCalculationSelector = state =>
  state.shared.root.isFetchingEntityDataCalculation;

export const isFetchingTaxReturnCalculationSelector = state =>
  state.shared.root.isFetchingTaxReturnCalculation;

export const isFetchingConsolidatedMembersTaxReturnCalculationSelector = state =>
  state.shared.root.isFetchingConsolidatedMembersTaxReturnCalculation;

export const isFetchingCalculationAllEntitiesForJurisdictionSelector = state =>
  state.shared.root.isFetchingCalculationAllEntitiesForJurisdiction;

export const isFetchingDREDataSelector = state => state.shared.root.isFetchingDREData;

export const isFetchingEntityBatchCalculationSelector = state =>
  state.shared.root.isFetchingEntityBatchCalculation;

export const isFetchingInitiateEntityBatchCalculationSelector = state =>
  state.shared.root.isFetchingInitiateEntityBatchCalculation;
