import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { entitySchemas } from '@common-packages/validators';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import isEqual from 'lodash.isequal';
import { Routes } from '@common-packages/routes-definitions';
import { Tooltip } from '@pwc/appkit-react';
import { useSelector } from 'react-redux';

import { isVerifiedRoleSelector } from '../../shared/store/customerData.selectors';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import DatePicker from '../../shared/forms/datePickerFormik/datePickerFormik.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';
import Checkbox from '../../shared/forms/checkboxFormik/checkboxFormik.component';
import {
  SelectOptionPropTypes,
  SelectOptionPropTypesAllowNullValue,
} from '../../shared/propTypes/selectOption';

import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';

import entityPropTypes from './entity.propTypes';

const { OrganizationType, ENTITY_TYPE_OPTIONS } = entitySchemas;

const renderError = message => <p className="invalid-feedback">{message}</p>;

const autoRefreshMessage = `This entity's federal data will not be automatically refreshed. You can manually refresh this entities data `;

const tooltipContent = 'Selection is disabled due to information flowing from GTW.';

const EntityForm = ({
  businessDescriptionOptions,
  federalFilingTypeOptions,
  legalEntitiesOptions,
  initialValues,
  isEditMode,
  isSubmitting,
  jurisdictionOptions,
  organizationTypeOptions,
  resetForm,
  submitForm,
  values,
  setFieldValue,
  entities,
  orgRuleIdOptions,
  partnershipSourceOptions,
  dcsHoLeidIndOptions,
  hoReportingPeriodOptions,
}) => {
  const isVerifiedRole = useSelector(isVerifiedRoleSelector);

  const isFormDataChanged = !isEqual(initialValues, values);

  const resetFormToInitial = useCallback(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    if (values.orgType === OrganizationType.LEGAL_ENTITY) {
      setFieldValue('legalEntityId', values.orgId);
    }
    if (values.federalLegalEntityId !== null) {
      setFieldValue('entityType', values.entityType);
    }
  }, [setFieldValue, values]);

  const handleOrgTypeSelect = useCallback(
    (fieldName, value) => {
      setFieldValue('orgType', value);
      if (value === OrganizationType.BUSINESS_UNIT) {
        setFieldValue('legalEntityId', '');
      }
    },
    [setFieldValue],
  );
  // Manual handle is required as for 'null' field value becomes nested, being { value: null, label: '' } instead of plain null
  const handlePartnershipSourceSelect = useCallback(
    data => {
      setFieldValue('partnershipSource', data.value);
    },
    [setFieldValue],
  );

  const orgIdUniquenessValidation = useCallback(
    value => {
      if (value && !isEditMode && entities.find(entity => entity.orgId === value)) {
        return 'Organizational Id must be unique';
      }

      return;
    },
    [entities, isEditMode],
  );

  const legalEntitiesWithCurrentOne = useMemo(
    () =>
      values.orgType === OrganizationType.NONCCORP && values.orgId
        ? [{ label: values.orgId, value: values.orgId }, ...legalEntitiesOptions]
        : legalEntitiesOptions,
    [legalEntitiesOptions, values.orgType, values.orgId],
  );

  return (
    <form>
      <div>
        <Field
          className="form-text"
          label="Tax Year"
          name="taxYear"
          component={Input}
          autoComplete="off"
          disabled
        />
        <Field
          className="form-text"
          label="Organizational Id"
          name="orgId"
          component={Input}
          autoComplete="off"
          disabled={isEditMode}
          maxLength={20}
          validate={orgIdUniquenessValidation}
        />
        <SDKCustomSelect
          wrapperClassName="form-text"
          appkitLabel="Organization Type"
          name="orgType"
          options={organizationTypeOptions}
          value={values.orgType}
          onSelect={handleOrgTypeSelect}
        />
        <Tooltip
          content={tooltipContent}
          placement="top"
          disabled={!isEditMode || values.federalLegalEntityId === null}
        >
          <Field
            wrapperClassName="form-text"
            label="Disregarded (DRE)"
            name="isDisregarded"
            component={Checkbox}
            disabled={isEditMode && values.federalLegalEntityId !== null}
          />
        </Tooltip>
        <ErrorMessage name="isDisregarded" render={renderError} />
        <Tooltip
          content={tooltipContent}
          placement="top"
          disabled={!isEditMode || values.federalLegalEntityId === null}
        >
          <Field
            wrapperClassName="form-text"
            label="Elimination"
            name="elimination"
            component={Checkbox}
            disabled={isEditMode && values.federalLegalEntityId !== null}
          />
        </Tooltip>
        <ErrorMessage name="elimination" render={renderError} />
        <Tooltip
          content={tooltipContent}
          placement="top"
          disabled={!isEditMode || values.federalLegalEntityId === null}
        >
          <SDKCustomSelect
            wrapperClassName="form-text"
            appkitLabel="Entity Type"
            options={ENTITY_TYPE_OPTIONS}
            name="entityType"
            value={values.entityType}
            disabled={isEditMode && values.federalLegalEntityId !== null}
          />
        </Tooltip>
        {values.orgType === OrganizationType.LEGAL_ENTITY ? (
          <Field
            className="form-text"
            label="Legal Entity Id"
            name="legalEntityId"
            component={Input}
            maxLength={20}
            disabled
          />
        ) : (
          <SDKCustomSelect
            wrapperClassName="form-text"
            appkitLabel="Legal Entity Id"
            options={legalEntitiesWithCurrentOne}
            name="legalEntityId"
            value={values.legalEntityId}
            virtualized
          />
        )}
        <Field
          className="form-text"
          label="Organization Name"
          name="orgName"
          component={Input}
          maxLength={200}
        />
        <SDKCustomSelect
          wrapperClassName="form-text"
          appkitLabel="Business"
          name="businessId"
          options={businessDescriptionOptions}
          showSearchOnToggle
          value={values.businessId}
        />
        <SDKCustomSelect
          wrapperClassName="form-text"
          appkitLabel="Org Rule Id"
          name="orgRuleId"
          options={orgRuleIdOptions}
          value={values.orgRuleId}
        />

        <SDKCustomSelect
          wrapperClassName="form-text"
          appkitLabel="State of Domicile"
          name="domicileJurisdictionId"
          options={jurisdictionOptions}
          showSearchOnToggle
          value={values.domicileJurisdictionId}
        />
        <SDKCustomSelect
          wrapperClassName="form-text"
          appkitLabel="Federal Filing Type"
          name="federalFilingType"
          options={federalFilingTypeOptions}
          showSearchOnToggle
          value={values.federalFilingType}
        />
        <SDKCustomSelect
          wrapperClassName="form-text"
          appkitLabel="Partnership Source"
          name="partnershipSource"
          options={partnershipSourceOptions}
          value={values.partnershipSource}
          onChange={handlePartnershipSourceSelect}
        />
        <Field
          wrapperClassName="form-text"
          label="Automatically refresh federal return data when available"
          name="autoFedRefresh"
          component={Checkbox}
        />
        {!values.autoFedRefresh && (
          <span>
            {autoRefreshMessage}
            <Link to={{ pathname: Routes.federalDataRefresh.MAIN }}>here</Link>
          </span>
        )}
        <Field
          className="form-text"
          label="Date Incorporated"
          name="dateIncorporated"
          component={DatePicker}
          value={values.dateIncorporated}
          clearable
        />
        <Field
          className="form-text"
          label="Acquisition Date"
          name="dateAcquired"
          component={DatePicker}
          value={values.dateAcquired}
          clearable
        />
        <Field
          className="form-text"
          label="Federal Id Number"
          name="fein"
          component={Input}
          type="number"
        />
        <Field
          className="form-text"
          label="Country of Incorporation Code"
          name="countryIncorporated"
          component={Input}
        />
        <Field wrapperClassName="form-text" label="Active" name="isActive" component={Checkbox} />
        <ErrorMessage name="isActive" render={renderError} />

        {isVerifiedRole && (
          <>
            <Field
              className="form-text"
              label="Scenario"
              name="scenario"
              component={Input}
              maxLength={20}
              value={values.scenario}
            />
            <Field
              className="form-text"
              label="Home Office Legal Entity Id"
              name="hoLeid"
              component={Input}
              maxLength={20}
              value={values.hoLeid}
            />
            <SDKCustomSelect
              wrapperClassName="form-text"
              appkitLabel="Description for Home Office Legal Entity ID"
              name="dcsHoLeidInd"
              options={dcsHoLeidIndOptions}
              value={values.dcsHoLeidInd}
            />
            <SDKCustomSelect
              wrapperClassName="form-text"
              appkitLabel="Home Ofice Reporting Period"
              name="hoReportingPeriod"
              options={hoReportingPeriodOptions}
              value={values.hoReportingPeriod}
            />
          </>
        )}
      </div>
      <div>
        <ButtonsGroup
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          onCancelClick={resetFormToInitial}
          submitButtonLabel="Save"
          disabled={!isFormDataChanged}
        />
      </div>
    </form>
  );
};

EntityForm.propTypes = {
  businessDescriptionOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  federalFilingTypeOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  initialValues: entityPropTypes.isRequired,
  entities: PropTypes.arrayOf(entityPropTypes),
  isEditMode: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  jurisdictionOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  organizationTypeOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  legalEntitiesOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  resetForm: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  values: entityPropTypes.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  orgRuleIdOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  partnershipSourceOptions: PropTypes.arrayOf(SelectOptionPropTypesAllowNullValue).isRequired,
  entityTypeOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
};

export default EntityForm;
