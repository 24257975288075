import { WorkpaperInstanceBody } from '@tls/slt-types';

import { HttpMethods } from '../enums';
import { QueryKeys, QueryError } from '../queries';

import { useCustomMutation } from '.';

type WorkpaperInstanceVariables = WorkpaperInstanceBody & {
  taxYear: string;
  period: string;
  businessEntityId: string;
  filingTypeId: string;
  jurisdictionId: string;
  parentDatasetInstanceId: string;
  datasetDefinitionId: string;
};

export const useMutationUpdateWorkpaperInstance = () =>
  useCustomMutation<null, QueryError, WorkpaperInstanceVariables, WorkpaperInstanceBody>({
    handleData: ({
      taxYear,
      period,
      filingTypeId,
      businessEntityId,
      jurisdictionId,
      datasetDefinitionId,
      parentDatasetInstanceId,
      datasetInstancesToDelete,
      datasetInstancesToAdd,
      datasetInstancesToUpdate,
    }) => ({
      url: `/api/shared/data-models/workpaper-instance-print-service/${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${jurisdictionId}/${parentDatasetInstanceId}/${datasetDefinitionId}`,
      method: HttpMethods.PUT,
      body: {
        datasetInstancesToDelete,
        datasetInstancesToAdd,
        datasetInstancesToUpdate,
      },
    }),
    resetKey: QueryKeys.WorkpaperInstance.Data,
    successMessage: 'Workpaper Instance data successfully updated',
    errorMessage: 'Updating Workpaper Instance data failed',
  });

export const useMutationRefreshCalcForWorkpaperInstance = () =>
  useCustomMutation<null, QueryError, WorkpaperInstanceVariables, WorkpaperInstanceBody>({
    handleData: ({
      taxYear,
      period,
      filingTypeId,
      businessEntityId,
      jurisdictionId,
      datasetDefinitionId,
    }) => ({
      url: `/api/shared/data-models/workpaper-instance-print-service/refreshCalc/${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${jurisdictionId}/${datasetDefinitionId}`,
      method: HttpMethods.POST,
    }),
    resetKey: QueryKeys.WorkpaperInstance.Data,
    successMessage: 'Refresh Calc call success',
    errorMessage: 'Refresh Calc call failed',
  });
