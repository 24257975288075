import React, { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import { Button, Tooltip } from '@pwc/appkit-react';
import { Routes } from '@common-packages/routes-definitions';
import { saveAs } from 'file-saver';
import { GridSizeChangedEvent, RowDragEvent, SelectionChangedEvent } from 'ag-grid-community';
import { pathToRegexp } from 'path-to-regexp';
import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
} from '@common-packages/customer-permissions-utils';

import {
  FindConsolidationsDetailsData,
  GlobalContext,
  TaxReturnResultRowWithCalcOptionsAndFilingAttributes,
  UpdateConsolidationTaxReturnData,
  UpdateConsolidationTaxReturnParams,
} from '../../../common/types';
import { globalContextSelector, customerPermissionsSelector } from '../shared/store/selectors';
import {
  useQueryConsolidatedTaxReturns,
  useQueryOrgType,
  useQueryReturnStatus,
  useQueryTaxReturn,
  useQueryTaxReturnItems,
  useQueryTaxReturns,
  useQueryTaxReturnsReturnDefinitions,
  useQueryTaxReturnsReturnDefinitionsJurisdictionOptions,
} from '../shared/queries/taxReturns';
import { useQueryDownloadReturnPdf } from '../shared/queries/binderMaintenance';
import {
  filingTypes,
  FILING_METHOD_OPTIONS,
  ROOT_NAVIGATOR_TAB,
  NEW_YORK_CITY_JURISDICTION_ID,
} from '../shared/constants';
import Loading from '../shared/displayComponents/loading.component';
import Header from '../shared/displayComponents/header/header.component';
import StickyFooter from '../shared/displayComponents/stickyFooter/stickyFooter.component';
import { FilingMethods, TaxReturnEFileStatus } from '../shared/enums';
import { useRowEditMode } from '../shared/editMode';
import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import useBooleanState from '../shared/hooks/useBooleanState.hook';
import {
  useMutationRemoveReturnItems,
  useMutationReorderReturnItems,
} from '../shared/mutations/binderMaintenance';
import { showConfirmModal } from '../shared/confirmModal/store/actions';
import AppkitIcon from '../shared/displayComponents/appkitIcon/appkitIcon.component';
import DataInfo from '../shared/displayComponents/dataInfo/dataInfo.component';
import AddEditReturnPanel from '../shared/taxReturns/addEditReturnPanel/addEditReturnPanel.container';
import AddEditConsolidationReturnPanel from '../shared/taxReturns/addEditConsolidatedReturnPanel/addEditReturnPanel.container';
import { useUpdateReturnStatus } from '../shared/mutations/taxReturns';
import config from '../config';
import { setActiveTab } from '../navigator/store';
import hasIndividualPartnersPermission from '../development/dataModels/hasIndividualPartnersPermission';
import RadioModal from '../shared/displayComponents/radioModal/radioModal.component';
import useModal from '../shared/hooks/useModal.hook';
import { SSN_MODAL_MESSAGE, SSN_MODAL_OPTION, SSN_OPTION_ENUM } from '../shared/enums/pdf';

import useGenerateReturnPdf from './hooks/useGenerateReturnPdf.hook';
import ReturnMaintenancePanel from './returnMaintenancePanel.component';
import styles from './returnMaintenance.module.scss';
import getColumnDefinitions from './returnMaintenance.columnDefinitions';
import DownloadPdfPanel from './downloadPdfPanel/downloadPdfPanel.container';
import AddFormsPanel from './addReturnItems/addFormsPanel.container';
import AddPdfsPanel from './addReturnItems/addPdfsPanel.component';
import useFilteredReturnItemIds from './useFilteredReturnItemIds';
import { useSyncGlobalContextWithTaxReturn } from './hooks/useSyncGlobalContextWithTaxReturn';

export enum PanelComponents {
  DOWNLOAD_PDF,
  GENERATE_PDF,
  PDF_READY,
  PDF_ERROR,
}

interface ReturnItemRowDefinition {
  rowId: number;
  isCheckboxSelected?: boolean;
}
const getRowNodeId = ({ data: { rowId } }: { data: { rowId: number } }) => rowId;

const INDIVIDUAL_PARTNER = 'I';

const ReturnMaintenance = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const customerPermissions = useSelector(customerPermissionsSelector);
  const hasAccessToEfile = hasIndividualPartnersPermission(customerPermissions);
  const { taxYear, period, entityId, filingTypeId } = globalContext;
  const isSeparateReturn = filingTypeId === filingTypes.SEPARATE;
  const { returnId } = useParams<{ returnId: string }>();
  const [isRemoveEnabled, setIsRemoveEnabled] = useState(false);
  const [shouldRevealSSNOption, setShouldRevealSSNOption] = useState<string>(SSN_OPTION_ENUM.HIDE);
  const [showfilingInfoDescription, setShowfilingInfoDescription] = useState(false);
  const filingInfoRef = useRef<HTMLDivElement | null>(null);

  const { showModal: showSSNOptionModal, modalProps: showSSNOptionModalProps } = useModal();

  const { mutateAsync: removeReturnItems } = useMutationRemoveReturnItems({
    onSuccess: () => setIsRemoveEnabled(false),
  });
  const { mutateAsync: reorderReturnItems } = useMutationReorderReturnItems();

  const [
    isEditTaxReturnPanelVisible,
    showEditTaxReturnPanel,
    hideEditTaxReturnPanel,
  ] = useBooleanState(false);

  const [activeComponent, setActiveComponent] = useState(PanelComponents.DOWNLOAD_PDF);
  const [isDownloadPdfPanelVisible, showDownloadPdfPanel, hideDownloadPdfPanel] = useBooleanState(
    false,
  );
  const [isFormsPanelVisible, showFormsPanel, hideFormsPanel] = useBooleanState(false);
  const [isPdfsPanelVisible, showPdfsPanel, hidePdfsPanel] = useBooleanState(false);
  const [shouldDecrypt, setShouldDecrypt] = useState(false);

  const { data: taxReturn, isLoading: isLoadingTaxReturn } = useQueryTaxReturn({
    params: { returnId, apiRouteParamString: globalContext.apiRouteParamString },
    enabled: Boolean(returnId),
  });

  const { data: orgTypeData, isLoading: isLoadingOrgType } = useQueryOrgType({
    params: { taxYear: taxYear || '', period: period || '', entityId: entityId || '' },
    enabled: Boolean(taxYear && period && entityId),
  });

  const { mutateAsync: updateReturnStatus } = useUpdateReturnStatus();

  const { data: returnStatusOption } = useQueryReturnStatus({
    filingMethod: taxReturn?.filingMethod,
    efileStatus: taxReturn?.efileStatus as TaxReturnEFileStatus | null | undefined,
    returnStatus: taxReturn?.returnStatus,
  });

  const { data: taxReturnItems, isLoading: isLoadingTaxReturnItems } = useQueryTaxReturnItems({
    params: {
      returnId,
    },
    enabled: Boolean(returnId),
  });

  // get tax returns for separate
  const { data: separateTaxReturns, isLoading: isLoadingSeparateTaxReturns } = useQueryTaxReturns({
    params: { taxYear: taxYear || '', period: period || '', entityId: entityId || '' },
    enabled: Boolean(taxYear && period && entityId && isSeparateReturn),
  });

  // get tax returns for consolidation
  const {
    data: consolidationTaxReturns,
    isLoading: isLoadingConsolidationTaxReturns,
  } = useQueryConsolidatedTaxReturns({
    params: { taxYear: taxYear || '', period: period || '' },
    enabled: Boolean(taxYear && period && !isSeparateReturn),
  });

  const taxReturns = isSeparateReturn ? separateTaxReturns : consolidationTaxReturns;
  const isLoadingTaxReturns = isSeparateReturn
    ? isLoadingSeparateTaxReturns
    : isLoadingConsolidationTaxReturns;

  const {
    data: taxReturnsDefinitions,
    isLoading: isLoadingTaxReturnsDefinitions,
  } = useQueryTaxReturnsReturnDefinitions({
    params: {
      taxYear: taxYear || '',
      period: period || '',
      filingTypeId: filingTypeId || '',
      entityId: entityId || '',
    },
    enabled: Boolean(taxYear && period && filingTypeId && !(isSeparateReturn && !entityId)),
  });

  const {
    data: taxReturnsDefinitionsJurisdictionOptions,
    isLoading: isLoadingTaxReturnsDefinitionsJurisdictionOptions,
  } = useQueryTaxReturnsReturnDefinitionsJurisdictionOptions({
    params: { taxYear: taxYear || '', filingTypeId: filingTypeId || '' },
    enabled: Boolean(taxYear && filingTypeId),
  });

  const { isFetching: isDownloading, refetch: downloadReturnPdf } = useQueryDownloadReturnPdf({
    params: { returnId, shouldDecrypt },
    enabled: false,
  });

  const filingInformation = useMemo(() => {
    const _returnDefinition = taxReturnsDefinitions?.filter(
      item => item?.filingFormId === taxReturn?.filingFormId,
    )[0];
    return _returnDefinition && _returnDefinition?.filingInformation?.length
      ? _returnDefinition?.filingInformation
      : null;
  }, [taxReturn, taxReturnsDefinitions]);

  const taxReturnToEdit = useMemo(() => {
    const data: (
      | TaxReturnResultRowWithCalcOptionsAndFilingAttributes
      | FindConsolidationsDetailsData
    )[] = taxReturns?.data || [];
    return (
      data.find(taxReturnData => {
        const returnId = isSeparateReturn
          ? (taxReturnData as TaxReturnResultRowWithCalcOptionsAndFilingAttributes).taxReturn
              ?.returnId
          : taxReturnData.returnId;
        return returnId === taxReturn?.returnId;
      }) || null
    );
  }, [taxReturn, taxReturns, isSeparateReturn]);

  const hasPermissionToViewIndividuals = hasPermissionToAccess({
    customerPermissions,
    permissionType: PERMISSION_TYPE.READ,
    routeDefinitions: [PERMISSION.INDIVIDUAL_PARTNERS],
  });

  const isIndividualPartnersAndHasPermission = useMemo(
    () =>
      orgTypeData && !isLoadingOrgType
        ? orgTypeData?.orgType === INDIVIDUAL_PARTNER && hasPermissionToViewIndividuals
        : false,
    [orgTypeData, hasPermissionToViewIndividuals, isLoadingOrgType],
  );

  const downloadPdf = useCallback(
    (shouldDecrypt: boolean) => {
      setShouldDecrypt(shouldDecrypt);
      setTimeout(async () => {
        const response = await downloadReturnPdf();
        if (!response.data) {
          return;
        }
        const data = new Blob([response.data]);
        saveAs(
          data,
          `${
            taxReturn?.filingTypeId === filingTypes.CONSOLIDATED
              ? taxReturn?.consolidationId
              : taxReturn?.entityId
          } - ${taxReturn?.jurisdictionCode} - ${taxReturn?.formName} Return.pdf`,
        );
        setActiveComponent(PanelComponents.PDF_READY);
      }, 0);
    },
    [
      downloadReturnPdf,
      taxReturn?.filingTypeId,
      taxReturn?.consolidationId,
      taxReturn?.entityId,
      taxReturn?.jurisdictionCode,
      taxReturn?.formName,
    ],
  );

  const {
    generatePdf,
    displayPdfGenerationDate,
    latestPdfReturnGenerationJobId,
    pdfLastGenerationDate,
    isProcessingGenerationJob,
  } = useGenerateReturnPdf({
    downloadPdf,
    setActiveComponent,
    isDownloadPdfPanelVisible,
    returnId,
  });

  const { returnFormItemAndOrgIds, returnPdfIds } = useFilteredReturnItemIds(taxReturnItems || []);

  const primaryForm = useMemo(
    () =>
      taxReturnItems?.find(
        item => item.isPrimaryForm && (item.consolidationId || !globalContext.isConsolidated),
      ),
    [taxReturnItems, globalContext.isConsolidated],
  );

  const returnItems = useMemo(() => taxReturnItems?.filter(item => item !== primaryForm), [
    taxReturnItems,
    primaryForm,
  ]);

  const { onGridReady, clonedRowData, gridApi, getAllRows } = useRowEditMode({
    rowData: returnItems || [],
    getUniqueRowId: getRowNodeId,
    saveButtonDisabled: false,
  });

  const onSelectionChanged = useCallback(
    ({ api }: SelectionChangedEvent) => {
      setIsRemoveEnabled(api.getSelectedNodes().length > 0);
    },
    [setIsRemoveEnabled],
  );

  const handleRowDragEnd = useCallback(
    async ({ api, overIndex }: RowDragEvent) => {
      api.refreshCells();
      api.ensureIndexVisible(overIndex);
      const returnItems = [...(primaryForm ? [primaryForm] : []), ...getAllRows()];

      await reorderReturnItems({
        binderItems: returnItems.map((returnItem, index) => ({
          id: returnItem.rowId,
          displayOrder: index + 1,
        })),
      });
    },
    [getAllRows, reorderReturnItems, primaryForm],
  );

  const onDownloadClick = useCallback(() => {
    if (!latestPdfReturnGenerationJobId) {
      if (isIndividualPartnersAndHasPermission) {
        showSSNOptionModal(SSN_MODAL_OPTION);
        return;
      }
      generatePdf();
      showDownloadPdfPanel();
    } else {
      setActiveComponent(PanelComponents.DOWNLOAD_PDF);
      showDownloadPdfPanel();
    }
  }, [
    showSSNOptionModal,
    generatePdf,
    latestPdfReturnGenerationJobId,
    showDownloadPdfPanel,
    isIndividualPartnersAndHasPermission,
  ]);

  const onConfirmSSNSelection = useCallback(
    (selectedOption: string | null | undefined) => {
      generatePdf(selectedOption === SSN_OPTION_ENUM.SHOW);
      setShouldRevealSSNOption(selectedOption || SSN_OPTION_ENUM.HIDE);
      showDownloadPdfPanel();
    },
    [generatePdf, showDownloadPdfPanel],
  );
  const onAddFormsClick = useCallback(() => {
    showFormsPanel();
  }, [showFormsPanel]);

  const onAddPdfsClick = useCallback(() => {
    showPdfsPanel();
  }, [showPdfsPanel]);

  const onRemoveClick = useCallback(() => {
    const selectedRows = gridApi?.getSelectedRows() || [];
    dispatch(
      showConfirmModal({
        title: `Remove Return ${selectedRows.length > 1 ? 'Items' : 'Item'}`,
        text: `Are you sure you want to remove ${selectedRows.length} return ${
          selectedRows.length > 1 ? 'items' : 'item'
        }?`,
        confirmCallback: () =>
          removeReturnItems({
            binderItemIds: selectedRows.map((row: ReturnItemRowDefinition) => row.rowId),
          }),
      }),
    );
  }, [dispatch, gridApi, removeReturnItems]);

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        globalContext,
        isSeparateReturn: Boolean(taxReturn?.entityId),
        filingMethod: taxReturn?.filingMethod,
      }),
    [globalContext, taxReturn?.entityId, taxReturn?.filingMethod],
  );

  const taxReturnContext = useMemo(
    () => ({
      taxYear: taxReturn?.taxYear || null,
      period: taxReturn?.period || null,
      filingTypeId: taxReturn?.filingTypeId || null,
      businessEntityId:
        (taxReturn?.filingTypeId === filingTypes.SEPARATE
          ? taxReturn?.entityId
          : taxReturn?.consolidationId) || null,
      jurisdictionId: taxReturn?.jurisdictionId || null,
      consolidationId: taxReturn?.consolidationId || null,
    }),
    [taxReturn],
  );

  const handleGridSizeChanged = useCallback(({ api }: GridSizeChangedEvent) => {
    api.sizeColumnsToFit();
  }, []);

  useSyncGlobalContextWithTaxReturn(taxReturn);

  const handleEditTaxReturn = useCallback(() => {
    showEditTaxReturnPanel();
  }, [showEditTaxReturnPanel]);

  const editReturnButton = useMemo(
    () => (
      <Button
        size="lg"
        kind="secondary"
        onClick={handleEditTaxReturn}
        disabled={isLoadingTaxReturn || !taxReturn}
        isLoading={isLoadingTaxReturns}
      >
        EDIT RETURN
      </Button>
    ),
    [handleEditTaxReturn, isLoadingTaxReturn, isLoadingTaxReturns, taxReturn],
  );

  const onChangeHandler = useCallback(
    (selected: { value: string | number | boolean | null }) => {
      if (selected?.value) {
        updateReturnStatus({
          taxReturnStatus: Number(selected.value),
          returnId: Number(returnId),
          optimisticUpdateTaxReturnsParams: {
            taxYear: taxYear || '',
            period: period || '',
            entityId: entityId || '',
            isSeparateReturn,
          },
        });
      }
    },
    [updateReturnStatus, returnId, taxYear, period, entityId, isSeparateReturn],
  );

  const onUpdateConsolidationSuccess = useCallback(
    (
      { binderId }: UpdateConsolidationTaxReturnData,
      { consolidation }: UpdateConsolidationTaxReturnParams,
    ) => {
      if (binderId !== consolidation.returnId) {
        setTimeout(() => {
          history.replace(Routes.returnMaintenance.compiledRoute({ returnId: `${binderId}` }));
        });
      }
    },
    [updateReturnStatus, returnId, taxYear, period, entityId],
  );

  const noForms = useMemo(() => !primaryForm && !clonedRowData.length, [
    primaryForm,
    clonedRowData,
  ]);

  const isFilingInfoOverFlow = useMemo(() => {
    if (filingInfoRef.current && filingInformation?.length) {
      return filingInfoRef.current.scrollWidth > filingInfoRef.current.clientWidth;
    }
    return false;
  }, [filingInfoRef.current, filingInformation]);

  useEffect(() => {
    // when changing taxRetrun or ReturnsDefinitions reset the showfilingInfoDescription to default
    setShowfilingInfoDescription(false);
  }, [isLoadingTaxReturn, isLoadingTaxReturnsDefinitions]);

  const handleClickDots = useCallback(() => setShowfilingInfoDescription(true), []);

  const dynamicEle = useMemo(() => {
    if (isLoadingTaxReturn) {
      return <Loading isLoading />;
    }

    if (!taxReturn) {
      return <DataInfo textToRender="Not found" />;
    }

    const isReturnStatusSelectionEnabled =
      null !== taxReturn.filingMethod && taxReturn.filingMethod !== FilingMethods.NON_FILING;

    const isNycReturn = taxReturn.jurisdictionId === NEW_YORK_CITY_JURISDICTION_ID.toString();
    const isExtensionReturn = !isNycReturn && (period === '5' || period === null);

    return (
      <div className={styles.returnMaintenancePageContainer}>
        <Header title={taxReturn.formName} description="Add documents to include in this return." />
        <div className={styles.returnInformationPanelContainer}>
          <div className={styles.returnInformationPanel}>
            <div className={styles.returnInformationPanelHeader}>
              <h4>Return Information</h4>
              {editReturnButton}
            </div>
            <div className="row">
              <div className="col-5">
                <div className={styles.returnInformationField}>
                  <label className={styles.returnInformationLabel}>Form Description:</label>
                  <span>{taxReturn.formDescription}</span>
                </div>
              </div>

              <div className="col">
                <div className={styles.returnInformationField}>
                  <label className={styles.returnInformationLabel}>Tax Type:</label>
                  <span>
                    {taxReturn.incomeTaxIndicator && 'Income Tax'}
                    {taxReturn.incomeTaxIndicator && taxReturn.franchiseTaxIndicator && ', '}
                    {taxReturn.franchiseTaxIndicator && 'Franchise Tax'}
                  </span>
                </div>
              </div>

              <div className="col">
                <div className={styles.returnInformationField}>
                  <label className={styles.returnInformationLabel}>Filing Method:</label>
                  <span>
                    {
                      FILING_METHOD_OPTIONS.find(({ value }) => value === taxReturn.filingMethod)
                        ?.label
                    }
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <ReturnMaintenancePanel
                taxReturn={taxReturn}
                returnStatusOption={returnStatusOption}
                onChangeHandler={onChangeHandler}
                isReturnStatusSelectionEnabled={isReturnStatusSelectionEnabled}
              />
            </div>
          </div>
          {filingInformation?.length && (
            <>
              <div className={styles.filingInformationContainer}>
                <div className={styles.filingInformationWrapper}>
                  <Tooltip
                    content={
                      <div className={styles.tooltipContentWrapper}>{filingInformation}</div>
                    }
                    placement="bottom"
                    tooltipTheme="light"
                  >
                    <AppkitIcon
                      className={styles.filingInformationIcon}
                      icon="information"
                      type="fill"
                      size={14}
                    />
                  </Tooltip>
                  <div
                    ref={filingInfoRef}
                    className={`${styles.filingInformationContent} ${
                      showfilingInfoDescription ? '' : styles.filingInformationContentOverflow
                    }`}
                  >
                    {`Filing Information: ${filingInformation}`}
                  </div>
                  {isFilingInfoOverFlow && !showfilingInfoDescription && (
                    <div className={styles.dots} onClick={handleClickDots}>
                      ...
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>

        <div className={styles.returnInformationGridPanel}>
          <div className={styles.gridButtonsWrapper}>
            <Button
              className={styles.gridButton}
              onClick={onAddFormsClick}
              kind="secondary"
              size="lg"
            >
              ADD FORMS
            </Button>
            <Button
              disabled={isExtensionReturn}
              className={styles.gridButton}
              onClick={onAddPdfsClick}
              kind="secondary"
              size="lg"
            >
              ADD PDFS
            </Button>
            <Button
              className={styles.gridButton}
              onClick={onRemoveClick}
              kind="secondary"
              size="lg"
              disabled={!isRemoveEnabled}
            >
              REMOVE
            </Button>
          </div>
          <div className={styles.gridWrapper}>
            <AgGrid
              rowData={clonedRowData}
              onGridReady={onGridReady}
              isGridLoading={isLoadingTaxReturnItems}
              columnDefs={columnDefinitions}
              rowSelection="multiple"
              rowMultiSelectWithClick={false}
              suppressRowClickSelection
              autoMaxWidth
              rowDragManaged
              rowDragMultiRow
              pinnedTopRowData={primaryForm && [primaryForm]}
              overlayNoRowsTemplate={
                !noForms ? '<span />' : '<span>No Forms or PDFs in Return<span />'
              }
              suppressSizeColumnsToFit={false}
              onGridSizeChanged={handleGridSizeChanged}
              onSelectionChanged={onSelectionChanged}
              onRowDragEnd={handleRowDragEnd}
              onRowDragLeave={handleRowDragEnd}
            />
          </div>
        </div>
        <StickyFooter className={styles.footer}>
          {displayPdfGenerationDate()}
          <Button
            onClick={onDownloadClick}
            kind="secondary"
            size="lg"
            disabled={isProcessingGenerationJob || noForms}
          >
            <AppkitIcon
              className={styles.downloadIcon}
              icon="download-light"
              type="outline"
              size={16}
            />
            Download
          </Button>
          {taxReturn.filingMethod === FilingMethods.EFILE && (
            <Link to={Routes.currentReturnFilingV2.compiledRoute({ returnId })}>
              <Tooltip
                content="Individual Partners permission is required to access this feature"
                placement="top"
                disabled={orgTypeData?.orgType !== INDIVIDUAL_PARTNER || hasAccessToEfile}
              >
                <Button
                  size="lg"
                  disabled={
                    noForms ||
                    !taxReturn.isEfileReady ||
                    (orgTypeData?.orgType === INDIVIDUAL_PARTNER && !hasAccessToEfile)
                  }
                >
                  GO TO E-FILE
                </Button>
              </Tooltip>
            </Link>
          )}
        </StickyFooter>
        <DownloadPdfPanel
          generatePdf={generatePdf}
          downloadPdf={downloadPdf}
          activeComponent={activeComponent}
          isDownloading={isDownloading}
          hidePanel={hideDownloadPdfPanel}
          isPanelVisible={isDownloadPdfPanelVisible}
          pdfLastGenerationDate={pdfLastGenerationDate}
          isIndividualPartnersAndHasPermission={isIndividualPartnersAndHasPermission}
          shouldRevealSSNOption={shouldRevealSSNOption}
          setShouldRevealSSNOption={setShouldRevealSSNOption}
        />
        <AddFormsPanel
          isPanelVisible={isFormsPanelVisible}
          hidePanel={hideFormsPanel}
          returnFormItemAndOrgIds={returnFormItemAndOrgIds}
          isStackedReturn={taxReturn?.isStackedReturn}
          taxReturnContext={taxReturnContext}
        />
        <AddPdfsPanel
          isPanelVisible={isPdfsPanelVisible}
          hidePanel={hidePdfsPanel}
          returnPdfIds={returnPdfIds}
          taxYear={taxReturn.taxYear}
          period={taxReturn.period}
          jurisdictionId={taxReturn.jurisdictionId}
        />
      </div>
    );
  }, [
    displayPdfGenerationDate,
    downloadPdf,
    generatePdf,
    handleGridSizeChanged,
    handleRowDragEnd,
    onAddFormsClick,
    onAddPdfsClick,
    onDownloadClick,
    onGridReady,
    onRemoveClick,
    onSelectionChanged,
    onChangeHandler,
    setShouldRevealSSNOption,
    handleClickDots,
    filingInformation,
    activeComponent,
    clonedRowData,
    columnDefinitions,
    editReturnButton,
    hideDownloadPdfPanel,
    hideFormsPanel,
    hidePdfsPanel,
    isDownloadPdfPanelVisible,
    isDownloading,
    isFormsPanelVisible,
    isLoadingTaxReturn,
    isLoadingTaxReturnItems,
    isPdfsPanelVisible,
    isProcessingGenerationJob,
    isRemoveEnabled,
    pdfLastGenerationDate,
    primaryForm,
    returnFormItemAndOrgIds,
    returnId,
    returnPdfIds,
    taxReturn,
    taxReturnContext,
    filingTypeId,
    returnStatusOption,
    orgTypeData,
    hasAccessToEfile,
    isIndividualPartnersAndHasPermission,
    shouldRevealSSNOption,
    noForms,
    isFilingInfoOverFlow,
    showfilingInfoDescription,
  ]);

  useEffect(() => {
    if (pathToRegexp(Routes.returnMaintenance.MAIN).test(pathname) && config.DISPLAY_RETURNS_TAB) {
      dispatch(setActiveTab(ROOT_NAVIGATOR_TAB.RETURNS));
    }
  }, [dispatch, pathname]);

  return useMemo(
    () => (
      <>
        {dynamicEle}
        {isSeparateReturn ? (
          <AddEditReturnPanel
            taxReturnToEdit={taxReturnToEdit}
            isPanelVisible={isEditTaxReturnPanelVisible}
            hidePanel={hideEditTaxReturnPanel}
            taxReturns={separateTaxReturns?.data || []}
            taxReturnsColumnsBlueprint={separateTaxReturns?.columnsBlueprint || []}
            taxReturnsDefinitions={taxReturnsDefinitions || []}
            isLoadingTaxReturnsDefinitions={isLoadingTaxReturnsDefinitions}
            taxReturnsDefinitionsJurisdictionOptions={
              taxReturnsDefinitionsJurisdictionOptions || []
            }
            isLoadingTaxReturnsDefinitionsJurisdictionOptions={
              isLoadingTaxReturnsDefinitionsJurisdictionOptions
            }
          />
        ) : (
          <AddEditConsolidationReturnPanel
            hidePanel={hideEditTaxReturnPanel}
            isPanelVisible={isEditTaxReturnPanelVisible}
            taxReturnToEdit={taxReturnToEdit}
            columnsBlueprint={consolidationTaxReturns?.columnsBlueprint || []}
            taxReturns={consolidationTaxReturns}
            calcPreferencesDefinitions={consolidationTaxReturns?.calcPreferencesDefinitions || []}
            onUpdateConsolidationSuccess={onUpdateConsolidationSuccess}
          />
        )}

        <RadioModal
          {...showSSNOptionModalProps}
          title="Display SSN"
          submitText="OK"
          dismissText="Cancel"
          modalMessage={SSN_MODAL_MESSAGE}
          defaultOption={SSN_MODAL_OPTION[0]?.value}
          submitAction={onConfirmSSNSelection}
        />
      </>
    ),
    [
      onConfirmSSNSelection,
      dynamicEle,
      hideEditTaxReturnPanel,
      isEditTaxReturnPanelVisible,
      isLoadingTaxReturnsDefinitions,
      isLoadingTaxReturnsDefinitionsJurisdictionOptions,
      taxReturnToEdit,
      taxReturns,
      taxReturnsDefinitions,
      taxReturnsDefinitionsJurisdictionOptions,
      showSSNOptionModalProps,
    ],
  );
};

export default ReturnMaintenance;
