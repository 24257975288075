const Yup = require('yup');

const { alphanumericCharForEntity } = require('../validationRegex');

const OrganizationType = {
  BUSINESS_UNIT: 'B',
  LEGAL_ENTITY: 'L',
  NONCCORP: 'NONCCORP',
};

const OrganizationTypeLabel = {
  B: 'Branch',
  L: 'Legal',
  NONCCORP: 'Non C Corporation',
};

const PARTNERSHIP_SOURCE = {
  NO_SOURCE: {
    value: null,
    label: '',
  },
  FEDERAL_SYSTEM: {
    value: 'G',
    label: 'GTW',
  },
  SLT_SYSTEM: {
    value: 'S',
    label: 'SLT',
  },
};

const ENTITY_TYPE_OPTIONS = [
  {
    value: 0,
    label: '1120',
  },
  {
    value: 1,
    label: '1120PC',
  },
  {
    value: 2,
    label: '1120L',
  },
];

const partnershipSourceValues = Object.values(PARTNERSHIP_SOURCE).map(el => el.value);

const entityTypeOptionValues = Object.values(ENTITY_TYPE_OPTIONS).map(
  entityTypeOption => entityTypeOption.value,
);

const SAME_LEGAL_ENTITY_AND_ORG_ID_MSG =
  'Legal Entity must be the same as Organizational Id for Legal Entities';
const DIFFERENT_LEGAL_ENTITY_AND_ORG_ID_MSG = `When the Organizational Type is set to “Branch”, the Legal Entity ID is required.`;
const LEGAL_ENTITY_HAS_BRANCH_VALIDATION_MSG =
  'The associated branches either need to be also marked as inactive first or reassigned to a different legal.';
const INACTIVE_VALIDATION_MESSAGE =
  'An entity may not be marked as inactive when currently a member of 1 or more groups. Please remove this entity from <filingMembers>. Once it has been removed from all groups it may be set to inactive.';

const checkIfEntityHasBranch = (context, checkBoxValue, isEditMode) =>
  !(isEditMode && context.parent.orgIdCount > 1 && !checkBoxValue);

const checkIfEntityHasFilingMember = (context, checkBoxValue, isEditMode) =>
  !(isEditMode && context.parent?.filingMembers !== null && !checkBoxValue);

const getCommon = ({ isEditMode = false, filingMembers = null }) =>
  Yup.object().shape(
    {
      taxYear: Yup.string().label('Tax Year').required(),
      orgId: (isEditMode
        ? Yup.string().label('Organizational Id')
        : Yup.string()
            .label('Organizational Id')
            .matches(alphanumericCharForEntity.regex, alphanumericCharForEntity.message)
      )
        .when('orgType', {
          is: OrganizationType.LEGAL_ENTITY,
          then: Yup.string()
            .oneOf([Yup.ref('legalEntityId')], SAME_LEGAL_ENTITY_AND_ORG_ID_MSG)
            .required(),
        })
        .when('orgType', {
          is: OrganizationType.NONCCORP,
          then: Yup.string().required(),
        }),
      legalEntityId: Yup.string()
        .label('Legal Entity Id')
        .when('orgType', {
          is: OrganizationType.BUSINESS_UNIT,
          then: Yup.string()
            .notOneOf([Yup.ref('orgId')], DIFFERENT_LEGAL_ENTITY_AND_ORG_ID_MSG)
            .required(),
        })
        .when('orgType', {
          is: OrganizationType.LEGAL_ENTITY,
          then: Yup.string()
            .oneOf([Yup.ref('orgId')], SAME_LEGAL_ENTITY_AND_ORG_ID_MSG)
            .required(),
        })
        .when('orgType', {
          is: OrganizationType.NONCCORP,
          then: Yup.string().required(),
        }),
      orgName: Yup.string().label('Organization Name').required(),
      businessId: Yup.string().label('Business Type').required(),
      orgRuleId: Yup.string().label('Org Rule Id').max(20).required(),
      domicileJurisdictionId: Yup.string().label('Domicile Jurisdiction').required(),
      fein: Yup.string().label('Federal Id Number').max(11).nullable(),
      federalFilingType: Yup.string().label('Federal Filing Type').required(),
      countryIncorporated: Yup.string().label('Country Incorporated').length(2).required(),
      partnershipSource: Yup.mixed().label('Partnership Source').oneOf(partnershipSourceValues),
      elimination: Yup.bool(),
      isActive: Yup.bool()
        .label('Active')
        .required()
        .test(
          'active-filing-test',
          INACTIVE_VALIDATION_MESSAGE.replace('<filingMembers>', filingMembers),
          (checkBoxValue, context) =>
            checkIfEntityHasFilingMember(context, checkBoxValue, isEditMode),
        )
        .test(
          'active-branch-test',
          LEGAL_ENTITY_HAS_BRANCH_VALIDATION_MSG,
          (checkBoxValue, context) => checkIfEntityHasBranch(context, checkBoxValue, isEditMode),
        ),
      isDisregarded: Yup.bool().label('Disregarded (DRE)'),
      entityType: Yup.number()
        .label('Entity Type')
        .oneOf([...entityTypeOptionValues, null])
        .nullable()
        .required(),
    },
    [['elimination', 'isDisregarded']],
  );

module.exports = {
  getCommon,
  OrganizationType,
  OrganizationTypeLabel,
  PARTNERSHIP_SOURCE,
  ENTITY_TYPE_OPTIONS,
};
