import { Button } from '@pwc/appkit-react';

type SyncReturnButtonProps = {
  isDisabled: boolean;
  onClick: () => void;
  isLoading: boolean;
};

export const SyncReturnButton: React.FC<SyncReturnButtonProps> = ({
  isDisabled,
  onClick,
  isLoading,
}) => {
  return (
    <Button
      size="lg"
      className="add-button"
      disabled={isDisabled}
      onClick={onClick}
      kind="secondary"
      isLoading={isLoading}
    >
      SYNC RETURN
    </Button>
  );
};
