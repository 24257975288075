import React, { useState, useCallback, ChangeEvent } from 'react';
import { Tab, Tabs } from '@pwc/appkit-react';

import Header from '../../../shared/displayComponents/header/header.component';

import styles from './eFileSupport.module.scss';
import EFileByReturn from './eFileByReturn/eFileByReturn.container';
import XmlMappingElements from './xmlMappingElements/xmlMappingElements.container';

enum TabsTypes {
  EFILE_BY_RETURN = 'EFILE_BY_RETURN',
  // TRANSMITTER_SETUP = 'TRANSMITTER_SETUP',
  XML_MAPPING_ELEMENTS = 'XML_MAPPING_ELEMENTS',
}

const TabsDefinitions = [
  { type: TabsTypes.EFILE_BY_RETURN, label: 'E-File by Return' },
  // { type: TabsTypes.TRANSMITTER_SETUP, label: 'Transmitter Setup' },
  { type: TabsTypes.XML_MAPPING_ELEMENTS, label: 'XML Mapping Elements' },
];

const EFileSupport = () => {
  const [activeTab, setActiveTab] = useState(TabsDefinitions[0].type);

  const handleTabChange = useCallback((_: ChangeEvent, tab: TabsTypes) => setActiveTab(tab), []);

  const getActiveTabComponent = () => {
    switch (activeTab) {
      case TabsTypes.EFILE_BY_RETURN:
        return <EFileByReturn />;
      // case TabsTypes.TRANSMITTER_SETUP:
      //   return <TransmitterSetup />;
      case TabsTypes.XML_MAPPING_ELEMENTS:
        return <XmlMappingElements />;
      default:
        throw new Error('Unsupported tab type');
    }
  };

  return (
    <>
      <Header title="E-File Support" />
      <div className={styles.pageWrapper}>
        <div className={styles.tabs}>
          <Tabs size="md" value={activeTab} onChange={handleTabChange}>
            {TabsDefinitions.map(({ label, type }) => (
              <Tab id={type} value={type} label={label} key={type} />
            ))}
          </Tabs>
        </div>
        {getActiveTabComponent()}
      </div>
    </>
  );
};

export default EFileSupport;
